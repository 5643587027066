<template>
  <b-row class="mt-5 justify-content-center">
    <b-col
      cols="12"
      sm="6"
      md="6"
      lg="4"
      v-for="(item, index) in cardData"
      :key="index"
      class="mb-lg-0 mb-5"
    >
      <b-card
        :title="item.cardTitle"
        :class="['text-left', item.isActive == true ? 'active_card' : ' card']"
      >
        <b-card-title class="card-title2">
          <span v-if="item.currency">
            {{ item.currency }} <span v-if="monthly === false">{{ item.price * 12 }}</span> <span v-else>{{ item.price }}</span>
          </span>
          <span v-else>{{ item.cardTitle2 }}</span>
        </b-card-title>
        <b-card-sub-title class="mb-3">
          <span v-if="monthly">per month</span>
          <span v-else>per year</span>
        </b-card-sub-title>
        <div class="d-flex">
          <b-button :href="item.contact ? '#' : vendorUrl + '/sign-up'" class="card_btn px-5">{{ item.btnText }}</b-button>
        </div>
        <b-card-body class="px-0">
          <ul>
            <li v-for="(itx, index) in item.cardListItems" :key="index">
              <span class="list_marker">●</span> {{ itx }}
            </li>
          </ul>
        </b-card-body>
      </b-card>
    </b-col>
    <!-- <b-col cols="12" md="4" lg="4">
      <b-card title="Flex" class="text-left">
        <b-card-text>
          With supporting text below as a natural lead-in to additional content.
        </b-card-text>
        <b-button href="#" variant="primary">Go somewhere</b-button>
      </b-card>
    </b-col>
    <b-col cols="12" md="4" lg="4">
      <b-card title="Enterprise" class="text-left">
        <b-card-text>
          With supporting text below as a natural lead-in to additional content.
        </b-card-text>
        <b-button href="#" variant="primary">Go somewhere</b-button>
      </b-card>
    </b-col> -->
  </b-row>
</template>

<script>
export default {
  props: {
    monthly: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      cardData: [
        {
          isActive: true,
          cardTitle: "Standard",
          price: 3500,
          currency: "KES",
          btnText: "Get Started",
          cardListItems: [
            "Analytics and Reports",
            "Staff Management",
            "Appointment Management",
            "Services",
            "Inventory Management",
            "Payments*",
            "Membership Management*",
            "Form builder",
            "Website Builder",
            "Messages",
            "Marketing *",
            "Loyalty and Reward  programs",
            "Ahidi Shop",
          ],
        },
        {
          isActive: false,
          cardTitle: "Flex",
          cardTitle2: "5% per transaction",
          cardSubtitle: " ",
          btnText: "Get Started",
          cardListItems: [
            "Analytics and Reports",
            "Staff Management",
            "Appointment Management",
            "Services",
            "Inventory Management",
            "Payments*",
            "Membership Management*",
            "Form builder",
            "Website Builder",
            "Messages",
            "Marketing *",
            "Loyalty and Reward  programs",
          ],
        },
        {
          isActive: false,
          cardTitle: "Enterprise",
          cardTitle2: "Custom Pricing",
          cardSubtitle: "Contact Us",
          btnText: "Contact Us",
          contact: true,
        },
      ],
    };
  },
  computed: {
    vendorUrl() {
      return this.$store.getters.country.country_code === "KE" ? "https://vendor.ahidi.co.ke" : "https://vendor.ahidi.com.au";
    }
  },
};
</script>

<style scoped>
.card {
  border-radius: 20px 20px 0px 0px !important;
  height: 700px;
}
.active_card {
  background-color: #ff8200 !important;
  color: white;
}
.card-subtitle {
  height: 18px;
}
.active_card .card-subtitle {
  color: #ffff !important;
}
.card-title {
  font-size: 30px;
  font-weight: 600;
}
.card-title2 {
  font-size: 30px;
  font-weight: 400 !important;
}
.card_btn {
  background-color: transparent !important;
  border: 1px solid;
  border-radius: 0.5rem;
  color: #000;
  margin: auto;
  font-size: 20px;
}
.active_card .card_btn {
  background-color: #fff !important;
  color: #ff8200;
}
ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
ul .list_marker {
  align-items: baseline;
  font-size: 2rem;
}
ul li {
  line-height: 1 !important;
}
</style>