<template>
  <div>
    <h1 class="pricing_heading">Ready to get started?</h1>
    <h1 class="pricing_sub-heading">Choose the package that best suits you!</h1>
    <div class="d-flex align-items-center justify-content-center">
      <div class="d-flex mx-sm-2 mx-0">
        <p class="mb-0">Monthly</p>
        <b-form-checkbox v-model="monthly"  switch class="ml-2" @change="$emit('monthly', monthly)"> </b-form-checkbox>
      </div>
      <div class="d-flex mx-sm-2 mx-0">
        <p class="mb-0">
          Yearly
          <b-badge variant="#FFE87A" class="var-yellow">10% discount</b-badge>
        </p>
      </div>
      <div class="d-flex mx-sm-2 mx-0">
        <p class="mb-0">
          Bi-annualy<b-badge variant="#FF727780" class="var-pink"
            >5% discount</b-badge
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    monthly: true
  })
};
</script>

<style>
.pricing_heading {
  font-size: 45px;
  font-weight: 500;
}
.pricing_sub-heading {
  font-size: 20px;
  font-weight: 400;
}
.var-yellow {
  background-color: #ffe87a;
  margin-left: 5px;
}
.var-pink {
  background-color: #ff727780;
  margin-left: 5px;
}
</style>