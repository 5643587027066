<template>
  <b-container fluid="lg">
    <div class="pricing my-5">
      <pricing-header @monthly="setMonthly" />
      <pricing-section :monthly="monthly" />
    </div>
  </b-container>
</template>

<script>
import PricingHeader from "../components/pricing/PricingHeader.vue";
import PricingSection from "../components/pricing/PricingSection.vue";
export default {
  components: { PricingHeader, PricingSection },
  name: "Pricing",
  data: () => ({
    monthly: true,
  }),
  methods: {
    setMonthly(inter) {
      this.monthly = inter;
    }
  },
};
</script>

<style  scoped>
.pricing {
  text-align: center;
}
</style>